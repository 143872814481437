import * as styles from "./project.module.css"

import { BigPlayButton, Player } from "video-react"

import Image from "../../components/image/image"
import Layout from "../../components/layout"
import PrevNext from "../../components/prev-next/prev-next"
import ProjectFacts from "../../components/project-facts/project-facts"
import React from "react"
import Section from "../../components/section/section"
import Seo from "../../components/seo"
import Stage from "../../components/stage/stage"
import challengesImg from "../../images/projects/deputy-growth-experiments/challenges.png"
import geMacImg from "../../images/projects/deputy-growth-experiments/GE-Mac.png"
import learningsImg from "../../images/projects/deputy-growth-experiments/learnings.png"
import onboardingDemoDataImg from "../../images/projects/deputy-growth-experiments/onboarding-demo-data.png"
import performanceLoadingDemoImg from "../../images/projects/deputy-growth-experiments/performance-loading-demo.png"
import processImg1 from "../../images/projects/deputy-growth-experiments/process-1.jpg"
import processImg2 from "../../images/projects/deputy-growth-experiments/process-2.jpg"
import secondIterationImg from "../../images/projects/deputy-growth-experiments/2nd-iteration.png"
import sketchSummaryImg from "../../images/projects/deputy-growth-experiments/sketch-summary.png"
import steps1Img from "../../images/projects/deputy-growth-experiments/steps.png"
import steps2Img from "../../images/projects/deputy-growth-experiments/steps-2.png"
import steps3Img from "../../images/projects/deputy-growth-experiments/steps-3.png"
import steps4Img from "../../images/projects/deputy-growth-experiments/steps-4.png"
import twoOptionsModalImg from "../../images/projects/deputy-growth-experiments/2-options-modal.png"
import video from "../../images/loading_schedule_animation.mp4"

const DeputyProductTour = () => (
  <Layout>
    <Seo title="Onboarding Experiments" />

    <Section>
      <h1>Onboarding Experiments</h1>
      <p>
        From a Product Tour Research completed in late 2018, we got to know
        Deputy trial users, their needs as well as the main onboarding gaps. I
        designed a number of different solutions that allowed us to run A/B
        tests and to improve their journey.
      </p>

      <ProjectFacts
        className="mb-4 border-top"
        name="Deputy"
        type="UX/UI Design"
        year="2019"
      />

      <Image img={geMacImg} />

      <div className="row">
        <div className="col-12 col-md-6 border-right pr-md-5">
          <h2 className="mb-4">The challenge</h2>
          <p>
            Deputy trial users were hesitant on which option they should choose
            by simply not understanding the difference and being afraid of
            making the wrong decision. This often made users feel incompetent
            and frustrated.
          </p>
          <p>
            Our goal for this project was to help trial users who intended to
            see the product in action by surfacing contextual, personalised and
            industry customised data.
          </p>
          <p>
            The original onboarding was designed to give users flexibility by
            enabling them explore the product through demo or pro trial:
          </p>
          <ul>
            <li>Demo trial: skip business set up and use a demo account, or</li>
            <li>Pro trial: set up business details to get started</li>
          </ul>
        </div>
        <div className="col-12 col-md-6 pl-md-5">
          <h2 className="mb-4">My role</h2>

          <p>
            I was responsible for designing a solution that could onboard trial
            users by showing various values Deputy has to offer and build early
            trust.
          </p>
          <p>
            One of the challenges of my role was to design a few small
            components that could provide instant value and quick
            implementation. Using small components allows ease of A/B testing as
            opposed to large end to end experience.
          </p>
        </div>
      </div>
    </Section>

    <Section heading="Solution" className={styles.blueImgBackground}>
      <p>
        The following set of experiences were designed and developed for our
        trial users:
      </p>
      <ul>
        <li>
          All users will now enter their trial though one consolidated, 'Get
          Started' call to action. The, 'Skip and use demo account' call to
          action button has been removed
        </li>
        <li>
          Demo data available for those users who intended to see the product in
          action instantly
        </li>
        <li>
          Users are offered a clear starting point that helps to accelerate
          their learning
        </li>
      </ul>
      <p>
        We expect these changes to improve desktop conversion rates, in
        particular, self conversion rates for no/low touch trials within our key
        segments and is the first step towards a fully personalised trial
        experience.
      </p>
    </Section>

    <Section heading="What did the process look like?">
      <div className="d-flex justify-content-between flex-column flex-sm-row mb-5">
        <Stage stage="Concept">
          <ul className="text-left">
            <li>Sketch session</li>
            <li>Jobs-to-be-done</li>
            <li>Wireframes</li>
          </ul>
        </Stage>
        <Stage stage="Feedback">
          <ul className="text-left">
            <li>Tech &amp; Product</li>
            <li>User feedback</li>
          </ul>
        </Stage>
        <Stage stage="Design">
          <ul className="text-left">
            <li>User Interface</li>
            <li>Screen flows</li>
          </ul>
        </Stage>
        <Stage stage="Build">
          <ul className="text-left">
            <li>Front &amp; Back End</li>
            <li>A/B test set up</li>
          </ul>
        </Stage>
        <Stage stage="Release &amp; Learn">
          <ul className="text-left">
            <li>Data insights</li>
            <li>User feedback</li>
          </ul>
        </Stage>
      </div>

      <h3>Design kick off</h3>
      <p>
        To kick off the design phase and brainstorm on as many as possible
        design solutions, I organised and facilitated a sketch session where
        everyone could share and contribute. Normally the best solutions are
        those that come from various perspectives, thus, I decided to involve
        people with different skillsets:
      </p>
      <ul className="mb-5">
        <li>Mobile Designer</li>
        <li>Product Designer</li>
        <li>Front End Engineer</li>
        <li>Back End Engineer</li>
        <li>Product Manager</li>
        <li>Stakeholder (Director of Growth)</li>
      </ul>

      <Image img={processImg1} caption="Sketch session kick off" />

      <Image
        img={processImg2}
        caption="The team is always up for a fun sketch to warm up before a sketch session"
      />

      <Image img={sketchSummaryImg} />
    </Section>

    <Section>
      <Image img={steps1Img} />

      <Image img={twoOptionsModalImg} />

      <Image img={steps2Img} />

      <h3>Learnings</h3>

      <p>
        After a few rounds of testing, the following changes were made on the
        modal based on user feedback:
      </p>

      <ul className="mb-5">
        <li>
          ‘Get started’ copy on call to action button updated to, ‘Create a
          team’ as it’s a better respresentation of the action required. ‘Get
          started’ copy was also already used on a previous sign up screen
        </li>
        <li>
          ‘Book in a tutorial’ link was removed as users were not interested in
          that option, which proved to be an unnecessary distraction
        </li>
      </ul>

      <Image img={learningsImg} />
    </Section>

    <Section className={styles.peachBackground}>
      <Image img={secondIterationImg} />
    </Section>

    <Section>
      <Image img={steps3Img} />

      <h3>Onboarding users with industry demo data</h3>
      <p>
        To present users with ongoing improvements during onboarding, we
        introduced the following features:
      </p>
      <ul className="mb-5">
        <li>
          <strong>Industry and area selectors</strong> added to the Create team
          modal where users can add or correct their industry if needed to
          produce relevant areas of work
        </li>
        <li>
          An option to <strong>‘create demo shifts for each employee’ </strong>
          is available through an added checkbox in the Create team modal. Once
          the team is created, the whole week’s rosters will be auto generated
          using industry specific areas and shift times
        </li>
        <li>
          The People tab now shows{" "}
          <strong> demo employees in a separate table</strong> from the main
          employee list, where it is clearly stated that the
          <strong>demo employees are free of charge</strong> and can be removed
          from the account if needed
        </li>
        <li>
          When a trial account with demo employees converts to a paid account,
          this instance will keep the demo employees and roll them over, free of
          charge
        </li>
      </ul>
    </Section>

    <Section className={styles.greyBackground}>
      <Image img={onboardingDemoDataImg} />
    </Section>

    <Section>
      <Image img={challengesImg} />
    </Section>

    <Section className={styles.peachBackground}>
      <img
        src={performanceLoadingDemoImg}
        className="img-fluid"
        alt="performance of loading the demo data"
      />
      <h2 className={styles.sectionHeader}>
        We're populating employees and industry shifts
      </h2>
      <div className={styles.video}>
        <Player autoPlay loop={true} fluid={true}>
          <source src={video} />
          <BigPlayButton position="center" />
        </Player>
      </div>
    </Section>

    <Section className={styles.blueImgBackground}>
      <Image img={steps4Img} />
      <h3>Outcomes </h3>
      <p>
        Two options modal, demo employees, industry areas and shifts were
        launched to all 100% of new trials to give them a clear starting point
        and instantly show the product in action.
      </p>
      <p>
        The new onboarding experience was successful, it helped to reduce
        drop-off rates by 12%, increased product engagement as well as the self
        conversion rate to 23%.
      </p>

      <PrevNext
        prev="/project/deputy-product-tour"
        next="/project/deputy-gamification"
      />
    </Section>
  </Layout>
)

export default DeputyProductTour
